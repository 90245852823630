<template>
  <div>
    <v-container style="padding-top: 2rem">
      <div>
        <v-btn color="primary" @click="alert = !alert">
          {{ alert ? "Afsluiten" : "Meer informatie" }}
        </v-btn>

        <div style="margin-top:10px">
          <v-alert
            :value="alert"
            color="info"
            dark
            border="top"
            icon="mdi-information-outline"
            transition="scale-transition"
          >
            Alle wijzigingen worden opgeslagen. Graag zorgvuldig zijn met alle
            aanpassingen,bij twijfel stuur een mail naar
            <strong>beheer@ameliewebdesign.nl</strong>
          </v-alert>
        </div>
      </div>
      <div>
        <h4>Welkom Administrator</h4>
      </div>
      <div class="row" style="margin-top:20px">
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link class="router-link" :to="{ name: 'Analytics' }">
            <div class="link-box">
              <img src="@/assets/user/data.png" alt="img" />
              <p class="link-box-title">Analytic</p>
              <p class="link-box-subtitle">Google analytic</p>
            </div>
          </router-link>
        </div>

        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link class="router-link" :to="{ name: 'ReserveringBeheer' }">
            <div class="link-box">
              <img src="@/assets/user/reserveringen.png" alt="img" />
              <p class="link-box-title">Reservering beheer</p>
              <p class="link-box-subtitle">Reserverigen bekijken</p>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link class="router-link" :to="{ name: 'AlleGebruikers' }">
            <div class="link-box">
              <img src="@/assets/user/user.png" alt="img" />
              <p class="link-box-title">Gebruikers bekijken</p>
              <p class="link-box-subtitle">Gebruikersbeheren</p>
            </div>
          </router-link>
        </div>

        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link class="router-link" :to="{ name: 'AlleWerkPlekken' }">
            <div class="link-box">
              <img src="@/assets/user/werkplekken.png" alt="img" />
              <p class="link-box-title">Werkplekken</p>
              <p class="link-box-subtitle">Werkplekken bekijken</p>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link class="router-link" :to="{ name: 'KennisItems' }">
            <div class="link-box">
              <img src="@/assets/user/kennis.png" alt="img" />
              <p class="link-box-title">Kennissysteem</p>
              <p class="link-box-subtitle">Kennissysteem beheren</p>
            </div>
          </router-link>
        </div>

        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link class="router-link" :to="{ name: 'UpdatesBeheer' }">
            <div class="link-box">
              <img src="@/assets/user/update.png" alt="img" />
              <p class="link-box-title">Systeem update</p>
              <p class="link-box-subtitle">Update beheren</p>
            </div>
          </router-link>
        </div>

        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link class="router-link" :to="{ name: 'BlogBeheren' }">
            <div class="link-box">
              <img src="@/assets/user/blog.png" alt="img" />
              <p class="link-box-title">Blog</p>
              <p class="link-box-subtitle">Blog beheren</p>
            </div>
          </router-link>
        </div>

        <div class="col-12 col-md-6 col-lg-4 mb-4" disabled>
          <router-link
            class="router-link"
            :to="{ name: 'AdministratieBeheren' }"
          >
            <div class="link-box">
              <img src="@/assets/user/bookkeeping.png" alt="img" />
              <p class="link-box-title">
                Administratie<span style="color:green">(Rapportage) </span>
              </p>
              <p class="link-box-subtitle">Administratie beheren</p>
            </div>
          </router-link>
        </div>

        <div class="col-12 col-md-6 col-lg-4 mb-4" disabled>
          <router-link class="router-link" :to="{ name: '' }">
            <div class="link-box">
              <img src="@/assets/user/log.png" alt="img" />
              <p class="link-box-title">
                Logbestanden <span style="color:red">(Nog niet actief) </span>
              </p>
              <p class="link-box-subtitle">Log bekijken en downloaden</p>
            </div>
          </router-link>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      user: null,
    };
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
      } else {
        this.user = null;
      }
    });
  },
  methods: {},
};
</script>

<style scoped>
.router-link {
  color: black;
  text-decoration: none;
}
.link-box {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 16px;
  text-align: center;
  /* min-height:100%; */
}
.link-box p {
  margin: 0px 0px 10px 0px;
}
.link-box img {
  height: 40px;
  margin-bottom: 10px;
}
.link-box-title {
  color: black;
  font-weight: bold;
  font-size: 18px;
}
.link-box-subtitle {
  color: black;
  font-size: 18px;
  margin-bottom: 10px;
}
</style>
